import axios from 'axios';
import {
  AddOrganizationCustomer,
  AddOrganizationProfile,
  AddOrganizationVendor,
  Apps,
  RejectTrustRequest,
  CreateOrganization,
  CreateOrganizationFromPending,
  EstablishTrust,
  GetOrganizationData,
  GetOrganizationPages,
  GetOrganizationProfiles,
  GetProfileNotificationsTemplates,
  GetProfileOrganizations,
  RemoveOrganizationProfile,
  RemoveProfileOrganization,
  RequestTrust,
  SetDefaultNotification,
  SetOrganizationName,
  SetOrganizationOkta,
  SetOrganizationPage,
  SetProfileLogo,
  SetProfileNotificationTemplate,
  SetProfileOrganizationRole,
  SetSubOrganization,
  TrustPartners,
  SetAppsParams,
  ChangeAppState,
  UploadSBOMFile,
  RemoveSBOMFile,
  GetOrganizationSecurity,
  SetOrganizationSecurity,
} from 'api/endpoints';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAuthenticationToken, ORG_ID_AUTH_KEY } from 'utils/auth';
import { getCookie } from 'utils/apiHelpers';
import { setOrganizations } from 'utils/organizations';
import { isDevelopment } from 'utils/index';
import { parseFileLinksFromAssessment } from 'utils/assessment';

export const getProfileOrganizationsRequest = async () => {
  const { profiles: organizations } = await axios.post(GetProfileOrganizations.url);
  // setting organizations to local storage
  setOrganizations(organizations || []);
  return organizations;
};

export const getProfileOrganizations = createAsyncThunk(
  'getOrganizations',
  getProfileOrganizationsRequest,
);

// organizations edit page
export const createOrganization = async (organizationName) => {
  const data = {
    'organization-name': organizationName,
  };

  return axios.post(CreateOrganization.url, data);
};

// pending assessment popup
export const createOrganizationFromPending = async (organizationName, assessments) => {
  const data = {
    'organization-name': organizationName,
    assessments: assessments.join(','),
  };

  return axios.post(CreateOrganizationFromPending.url, data);
};

export const removeProfileOrganization = async (organizationId) => {
  const token = getAuthenticationToken();
  const data = {
    'remove-profile-organization-id': organizationId,
    'remove-profile-profile-id': '',
    org_id: getCookie(ORG_ID_AUTH_KEY),
    token,
  };
  return axios.post(RemoveProfileOrganization.url, data);
};

export const setSubOrganization = async (organizationId) => {
  const data = {
    'set-sub-organization-organization-id': organizationId,
  };
  return axios.post(SetSubOrganization.url, data);
};

export const getOrganizationProfilesRequest = async ({ organizationId, orgIndex }) => {
  const token = getAuthenticationToken();

  return axios.post(GetOrganizationProfiles.url, {
    org_id: organizationId,
    token,
  });
};

export const getOrganizationProfiles = createAsyncThunk(
  'getOrganizationProfiles',
  getOrganizationProfilesRequest,
);

export const addOrganizationProfileRequest = async ({
  email,
  role,
  orgId,
  orgIndex,
  assessmentId,
}) => {
  const token = getAuthenticationToken();
  const data = {
    'add-organization-profile-email': email,
    role,
    org_id: orgId,
    token,
  };
  if (assessmentId) {
    data['add-organization-profile-assessment-id'] = assessmentId;
  }
  return axios.post(AddOrganizationProfile.url, data);
};

export const addOrganizationProfile = createAsyncThunk(
  'addOrganizationProfile',
  addOrganizationProfileRequest,
);

export const removeOrganizationProfileRequest = async ({ profileId, orgId, orgIndex }) => {
  const token = getAuthenticationToken();
  const data = {
    'remove-profile-profile-id': profileId,
    'remove-profile-organization-id': orgId,
  };

  if (isDevelopment()) {
    data['org_id'] = getCookie(ORG_ID_AUTH_KEY);
    data.token = token;
  }

  return axios.post(RemoveOrganizationProfile.url, data);
};

export const removeOrganizationProfile = createAsyncThunk(
  'removeOrganizationProfile',
  removeOrganizationProfileRequest,
);

export const getProfileNotificationsTemplates = async ({ orgId }) => {
  const token = getAuthenticationToken();
  const data = {
    org_id: orgId,
    token,
  };
  const { templates } = await axios.post(GetProfileNotificationsTemplates.url, data);
  return templates;
};

export const getOrganizationPages = async (orgId) => {
  const token = getAuthenticationToken();
  const data = {
    org_id: orgId,
    token,
  };
  const { pages } = await axios.post(GetOrganizationPages.url, data);
  return pages;
};

export const setProfileNotificationTemplate = async (
  orgId,
  { content, subject, test, key, disabled = false },
) => {
  const token = getAuthenticationToken();
  const data = {
    org_id: orgId,
    'set-profile-notification-template-key': key,
    'set-profile-notification-template-subject': subject,
    'set-profile-notification-template-content': content,
    'set-profile-notification-template-test': test,
    'set-profile-notification-template-disabled': disabled,
    token,
  };

  const { status } = await axios.post(SetProfileNotificationTemplate.url, data);
  return status;
};

export const setDefaultNotification = async (orgId, { key }) => {
  const token = getAuthenticationToken();
  const data = {
    org_id: orgId,
    'set-profile-notification-template-key': key,
    token,
  };

  const { status } = await axios.post(SetDefaultNotification.url, data);
  return status;
};

export const getOrganizationDataRequest = async (orgId) => {
  const token = getAuthenticationToken();
  const data = {
    org_id: orgId,
    token,
  };
  return axios.post(GetOrganizationData.url, data);
};

export const getOrganizationDataForBoPage = createAsyncThunk(
  'getOrganizationDataForBoPage',
  getOrganizationDataRequest,
);

export const getOrganizationDataForSoPage = createAsyncThunk(
  'getOrganizationDataForSoPage',
  getOrganizationDataRequest,
);

export const setOrganizationOkta = async (orgId, okta) => {
  const token = getAuthenticationToken();
  const data = {
    org_id: orgId,
    'set-okta-application-client-id': okta.applicationClientId,
    'set-okta-organization-url': okta.organizationUrl,
    'set-okta-admin-group': okta.groups.admin,
    'set-okta-bo-group': okta.groups.businessOwner,
    'set-okta-so-group': okta.groups.salesOwner,
    token,
  };

  const { status } = await axios.post(SetOrganizationOkta.url, data);
  return status;
};

export const setProfileOrganizationRole = async (orgId, profileId, role) => {
  const token = getAuthenticationToken();
  const data = {
    id: profileId,
    org_id: orgId,
    role,
    token,
  };

  const { status } = await axios.post(SetProfileOrganizationRole.url, data);
  return status;
};

export const setOrganizationPage = async (orgId, { description, page, title }) => {
  const token = getAuthenticationToken();
  const data = {
    org_id: orgId,
    'set-page-description': description || undefined,
    'set-page-page': page,
    'set-page-title': title || undefined,
    token,
  };

  const { status } = await axios.post(SetOrganizationPage.url, data);
  return status;
};

export const setOrganizationLogoRequest = async ({ orgId, orgIndex, file, abortController }) => {
  const data = {
    'set-logo-corporate-logo': file,
  };

  const response = await axios.post(SetProfileLogo.url, data, {
    forcedAuthorization: { org_id: orgId },
    signal: abortController?.signal,
  });
  return { ...response, index: orgIndex };
};

export const setOrganizationLogo = createAsyncThunk(
  'setOrganizationLogo',
  setOrganizationLogoRequest,
);

export const addOrganizationVendor = async (
  { assessment, files, fullname, email, company },
  orgId,
) => {
  const token = getAuthenticationToken();

  const fileLinks = parseFileLinksFromAssessment(assessment);
  const data = {
    organization: orgId,
    'vendor-name': fullname,
    'vendor-company': company,
    'vendor-email': email,
    'vendor-assessment': JSON.stringify(assessment),
    'file-links': JSON.stringify(fileLinks || {}),
    org_id: orgId,
    token,
  };

  const { status } = await axios.post(AddOrganizationVendor.url, data);
  return status;
};

export const addOrganizationCustomer = async ({ assessment, fullname, email, company }, orgId) => {
  const token = getAuthenticationToken();

  const fileLinks = parseFileLinksFromAssessment(assessment);

  const data = {
    organization: orgId, // should be probably removed from the call
    'customer-name': fullname,
    'customer-company': company,
    'customer-email': email,
    'customer-assessment': JSON.stringify(assessment),
    'file-links': JSON.stringify(fileLinks || {}),
    org_id: orgId,
    token,
  };

  const { status } = await axios.post(AddOrganizationCustomer.url, data);
  return status;
};

export const getOrganizationAppsRequest = async ({ organizationId }) => {
  const payload = {
    organization_id: organizationId,
  };
  return axios.post(Apps.url, payload);
};

export const getOrganizationApps = createAsyncThunk(
  'getOrganizationApps',
  getOrganizationAppsRequest,
);

export const addOrganizationParamsRequest = async (payload) => {
  return axios.post(SetAppsParams.url, payload);
};

export const addOrganizationParams = createAsyncThunk(
  'addOrganizationParams',
  addOrganizationParamsRequest,
);

export const changeOrganizationStateRequest = async (payload) => {
  return axios.post(ChangeAppState.url, payload);
};

export const changeOrganizationState = createAsyncThunk(
  'changeOrganizationState',
  changeOrganizationStateRequest,
);

export const uploadSBOMFileRequest = async (payload) => {
  return axios.post(UploadSBOMFile.url, payload);
};

export const uploadSBOMFile = createAsyncThunk('uploadSBOMFile', uploadSBOMFileRequest);

export const removeSBOMFileRequest = async (payload) => {
  return axios.post(RemoveSBOMFile.url, payload);
};

export const removeSBOMFile = createAsyncThunk('removeSBOMFile', removeSBOMFileRequest);

export const getTrustedPartnersRequest = async (org_id) => {
  const token = getAuthenticationToken();
  const payload = {
    org_id,
    token,
  };

  const { trustRelations } = await axios.post(TrustPartners.url, payload);

  return trustRelations;
};

export const requestTrustPartner = async (email) => {
  const payload = {
    email,
  };

  return axios.post(RequestTrust.url, payload);
};

export const establishTrustPartner = async (id) => {
  const payload = {
    'trust-id': id,
    organizations: [getCookie(ORG_ID_AUTH_KEY)],
  };

  return axios.post(EstablishTrust.url, payload);
};

export const establishTrustPartnerThunk = createAsyncThunk(
  'establishTrustPartner',
  establishTrustPartner,
);

export const establishTrustPartnersRequest = async (payload) => {
  return axios.post(EstablishTrust.url, {
    'trust-mapping': JSON.stringify(payload),
  });
};

export const establishTrustPartners = createAsyncThunk(
  'establishTrustPartnersRequest',
  establishTrustPartnersRequest,
);

export const rejectTrustPartner = async (id) => {
  const payload = {
    'trust-id': id,
  };

  return axios.post(RejectTrustRequest.url, payload);
};

export const rejectTrustPartnerThunk = createAsyncThunk('rejectTrustPartner', rejectTrustPartner);

export const setOrganizationName = async ({ orgId, title }) => {
  const token = getAuthenticationToken();
  const data = {
    organizationId: orgId,
    'newCorporateName': title,
    token,
  };

  const { status } = await axios.post(SetOrganizationName.url, data);
  return status;
};

export const getOrganizationSecurityRequest = async (organizationId) => {
  const payload = {
    organizationId,
  };

  return axios.post(GetOrganizationSecurity.url, payload);
};

export const getOrganizationSecurity = createAsyncThunk(
  'getOrganizationSecurity',
  getOrganizationSecurityRequest,
);

export const setOrganizationSecurityRequest = async ({ organizationId, settings }) => {
  const payload = {
    organizationId,
    ...settings,
  };

  return axios.post(SetOrganizationSecurity.url, payload);
};

export const setOrganizationSecurity = createAsyncThunk(
  'setOrganizationSecurity',
  setOrganizationSecurityRequest,
);
